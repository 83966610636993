<template>
    <div class="consultingService_wrap">
        <Head :centerInfo="true"></Head>
        <!-- <div class="mauto"> -->
            <div class="consultingService_content">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="软件推荐及使用" name="1"></el-tab-pane>
                    </el-tabs>
                    <div>
                        <div class="prism-player" ref="J_prismPlayer1" id="J_prismPlayer1"></div>
                    </div>
            </div>
            <div class="consultingService_jump">
                    <el-tabs v-model="activeName1" width="100%" @tab-click="handleClick">
                        <el-tab-pane label="联众产品推荐" name="1"></el-tab-pane>
                </el-tabs>
                <div>
                        <a href="http://newmall.ourgame.com/product.html?temId=79&title=%E5%A4%A7%E5%8E%85%E7%A4%BC%E7%89%A9%20&classId=141" target="_blank"> <img src="~assets/news1_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/product.html?temId=71&title=%E6%B8%B8%E4%B9%90%E8%B1%86%20&classId=133" target="_blank"> <img src="~assets/news2_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/product.html?temId=69&title=%E8%81%94%E4%BC%97%E4%BC%9A%E5%91%98%20&classId=131" target="_blank"> <img src="~assets/news3_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/product.html?temId=70&title=%E8%81%94%E4%BC%97%E6%A0%87%E5%BF%97%20&classId=132" target="_blank"> <img src="~assets/news4_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/" target="_blank"> <img src="~assets/news5_icon.png" alt=""> </a>
                </div>
                
            </div>
        </div>
    <!-- </div> -->
</template>

<script>
import Head from '@/components/head.vue';
  import { getVideoPlayAuth } from '@/api/authority'
export default {
  name: "consultingService",
  data() {
    return {
      activeName: '1',
      activeName1:"1",
      listData:{}
    };
  },
  components:{
      Head
  },
  mounted(){
      this.getVideoPlayAuthFn()
      window.scrollTo(0,0)
  },
  methods: {

    // 获取播放凭证
    getVideoPlayAuthFn() {
        getVideoPlayAuth({
            videoId: 'fdfb6a3ef1ce425dae3c276dfdbe879b'
        }).then(response => {
            if (response.data.code == 0) {
                this.createVideo(response.data.data)
            } else {
                this.$message({
                    'message': response.data.msg
                })
            }
        })
    },
       // 初始化播放控件
    createVideo(data) {
        var _this = this
        // this.player = ''
        // this.$refs.J_prismPlayer.innerHTML = ''
        this.player = new Aliplayer({
            id: 'J_prismPlayer1',
            width: '100%',
            height: '608px;',
            autoplay: 'true',
            playauth: data.playAuth,
            encryptType: 1, //当播放私有加密流时需要设置。
            vid: data.aliVideoId,
            cover: data.coverUrl,
            isLive: false,
            controlBarVisibility: 'hover',
            showBarTime: '10000',

            "extraInfo": {
                "crossOrigin": "anonymous"
            },
            "skinLayout": [{
                    "name": "bigPlayButton",
                    "align": "blabs",
                    "x": 30,
                    "y": 80
                },
                {
                    "name": "H5Loading",
                    "align": "cc"
                },
                {
                    "name": "errorDisplay",
                    "align": "tlabs",
                    "x": 0,
                    "y": 0
                },
                {
                    "name": "infoDisplay"
                },
                {
                    "name": "tooltip",
                    "align": "blabs",
                    "x": 0,
                    "y": 56
                },
                {
                    "name": "thumbnail"
                },
                {
                    "name": "controlBar",
                    "align": "blabs",
                    "x": 0,
                    "y": 0,
                    "children": [{
                            "name": "progress",
                            "align": "blabs",
                            "x": 0,
                            "y": 44
                        },
                        {
                            "name": "playButton",
                            "align": "tl",
                            "x": 15,
                            "y": 12
                        },
                        {
                            "name": "timeDisplay",
                            "align": "tl",
                            "x": 10,
                            "y": 7
                        },
                        {
                            "name": "fullScreenButton",
                            "align": "tr",
                            "x": 10,
                            "y": 12
                        },
                        {
                            "name": "subtitle",
                            "align": "tr",
                            "x": 15,
                            "y": 12
                        },
                        {
                            "name": "setting",
                            "align": "tr",
                            "x": 15,
                            "y": 12
                        },
                        {
                            "name": "volume",
                            "align": "tr",
                            "x": 5,
                            "y": 10
                        },
                        {
                            "name": "snapshot",
                            "align": "tr",
                            "x": 10,
                            "y": 12
                        }
                    ]
                }
            ],
            components: [
                // // 弹幕
                // {
                //     name: 'AliplayerDanmuComponent',
                //     type: AliPlayerComponent.AliplayerDanmuComponent,
                //     args: []
                // },
                // // 倍速

                // {
                //     name: 'RateComponent',
                //     type: AliPlayerComponent.RateComponent
                // },
                // // 清晰度
                // {
                //     name: 'QualityComponent',
                //     type: AliPlayerComponent.QualityComponent
                // },
            ],
        }, function (player) {
               player.on('snapshoted', function (data) {
                        var pictureData = data.paramData.base64
                        var downloadElement = document.createElement('a')
                        downloadElement.setAttribute('href', pictureData)
                        var fileName = 'Aliplayer' + Date.now() + '.png'
                        downloadElement.setAttribute('download', fileName)
                        downloadElement.click()
                        pictureData = null
                    })
        });
            },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>
<style lang="less">
    .consultingService_content{
        width: 1281px;
        height: 606px;
        background-color: #ffffff;
        border: solid 1px #eeeeee;
        padding:40px;
        margin:0px auto;
        margin-top:105px;
        box-sizing: border-box;
        .prism-player {
                            width: 1007px;
                            height: 445px;
                            position: relative;
                            top: 0px;
                            left: 0px;
                            margin:0px auto;

                        }
    }
    .consultingService_jump{
        margin-left:17px;
        padding-bottom: 60px;
               margin:0px auto;
        margin-top:40px;
             width: 1281px;
        overflow: hidden;
        a{
            display: block;
            float:left;
            margin-top:10px;
            img{
                	width: 186px;
	                height: 77px;
            }
        }
        a+a{
            margin-left:17px;
        }
    }
</style>